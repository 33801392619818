import React from "react";
import {
  Avatar,
  Checkbox,
  Link,
  VStack,
  Table,
  Tr,
  Td,
  useMediaQuery,
  Tbody,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../store/stateType";
import { get_odds } from "../../resources/arbitrageAPI";
import * as AllActionCreators from "../../actions/actionCreators";
import { bindActionCreators } from "redux";

export const BookiesList = () => {
  const dispatch = useDispatch();
  const { bookies, filepath, stake } = useSelector((state: StateType) => state);
  const [isMobile] = useMediaQuery("(min-width: 800px)");

  const { setArbitrages, setIsLoading } = bindActionCreators(
    AllActionCreators,
    dispatch,
  );

  const handeOnClickBookie = async (index: number) => {
    setIsLoading(true);

    bookies[index]["active"] = !bookies[index]["active"];

    const bookiesToSend = bookies.filter((b) => b.active).map((b) => b.name);

    const response = await get_odds(filepath, stake, bookiesToSend);
    const log_data = response.data;

    setArbitrages(log_data);

    return setIsLoading(false);
  };

  return (
    <VStack w="full" p="0.25em">
      <Table size="sm" w="75%">
        <Tbody>
          {bookies.map((b, i) => (
            <Tr>
              <Td align="center">
                <Link href={b.link} isExternal>
                  <Avatar
                    size="sm"
                    name={b.name}
                    src={`/static/${b.name
                      .toLowerCase()
                      .replace(" ", "_")}.jpg`}
                  />
                </Link>
              </Td>
              {isMobile ? <Td align="center">{b.name}</Td> : null}
              <Td align="center">
                <Checkbox
                  isChecked={b.active}
                  onChange={() => handeOnClickBookie(i)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};
