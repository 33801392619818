import moment from "moment";
import { StateType } from "./stateType";

const start = new Date();

export const initialState: StateType = {
  isLoading: false,
  stake: 100.0,
  logs: [
    {
      date: moment(start).format("YYYY-MM-DD HH:mm:ss"),
      severity: "info",
      message: "UI Loaded",
    },
  ],
  arbitrages: [],
  bookies: [],
  filepath: "",
  isArbModalOpen: false,
};
