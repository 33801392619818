import { AccordionItem, AccordionButton, Box, HStack } from "@chakra-ui/react";
import React from "react";
import { EntryType } from "../../../types/types";

type IncomeProps = {
  entry: EntryType;
};

export const Income = ({ entry }: IncomeProps) => {
  const { date, description, amount } = entry;

  return (
    <AccordionItem w="100%">
      <h2>
        <AccordionButton
          bg={amount > 0 ? "green.200" : "red.200"}
          _hover={undefined}
        >
          <Box flex="1" textAlign="left">
            <HStack justify="space-between">
              <div>{date}</div>
              <div>{description}</div>
              <div>
                {amount !== undefined &&
                  amount.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })}
              </div>
            </HStack>
          </Box>
        </AccordionButton>
      </h2>
    </AccordionItem>
  );
};
