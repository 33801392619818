import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Accordion,
  Box,
  Button,
  HStack,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import moment from "moment";
import { Bet } from "./Item/Bet";
import { Income } from "./Item/Income";
import { Expense } from "./Item/Expense";

import { serverUrl } from "../../config";
import { EntryType } from "../../types/types";

export const ProgressPage = () => {
  const { data } = useQuery("progressData", () =>
    fetch(`${serverUrl}/progress_data`).then((res) => res.json()),
  );

  const calculateTotal = () => {
    let total = 0;

    if (data === undefined) {
      return 0;
    }
    data.map((d: EntryType) => {
      if (d.type !== "bet") {
        total += d.amount;
      } else if (d.open) {
        total += d.amount;
      } else {
        if (d.bet) {
          total += d.bet.winnings;
        }
        return total;
      }
      return d;
    });
    return total;
  };

  const calculateOpenBets = () => {
    if (data === undefined) {
      return 0;
    }
    let newData = data.filter((d: EntryType) => d.type === "bet" && d.open);
    if (newData.length === 0) {
      return 0;
    } else {
      return newData
        .map((d: EntryType) => Math.abs(d.amount))
        .reduce((a: number, b: number) => a + b);
    }
  };

  const calcMonthOptions = () => {
    if (data === undefined) {
      return [];
    }

    const dates = data
      .map((d: EntryType) => moment(d.date, "YYYY-MM-DD").format("MMMM YYYY"))
      .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i); // get unique dates

    return dates;
  };

  const handleFilterMonth = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterMonth(e.target.value);
    handleFilterData(e.target.value, filterType);
  };

  const handleFilterType = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterType(e.target.value);
    handleFilterData(filterMonth, e.target.value);
  };

  const handleClearFilters = () => {
    setFilterMonth("");
    setFilterType("");
    setFilteredData(data);
  };

  const handleFilterData = (month: string, type: string) => {
    let newData: EntryType[] = JSON.parse(JSON.stringify(data));

    // Date
    const startDate = moment(month, "MMMM-YYYY");
    const endDate = moment(startDate).endOf("month");

    if (month !== "") {
      newData = newData.filter(
        (d) =>
          moment(d.date, "YYYY-MM-DD") >= startDate &&
          moment(d.date, "YYYY-MM-DD") <= endDate,
      );
    }

    // Type
    switch (type) {
      case "open":
        newData = newData.filter((d) => d.type === "bet" && d.open);
        break;
      case "closed":
        newData = newData.filter((d) => d.type === "bet" && !d.open);
        break;
      case "income":
        newData = newData.filter((d) => d.type === type);
        break;
      case "expense":
        newData = newData.filter((d) => d.type === type);
        break;
      case "withdraw":
        newData = newData.filter((d) => d.type === type);
        break;
      default:
        break;
    }

    setFilteredData(newData);
  };

  const [filteredData, setFilteredData] = useState<EntryType[]>([]);
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterType, setFilterType] = useState("all");

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  return (
    <VStack align="center" spacing={0} style={{ padding: "0.5em" }}>
      <Text fontSize="4xl" p="0.5em">
        Progress
      </Text>
      <HStack p="0.5em" w="60%">
        {/* Amount */}
        <Box maxW="md" w="100%" p={2}>
          <Text size="3xl">Total Cashflow</Text>
          {calculateTotal().toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          })}
        </Box>
        {/* Open Bets */}
        <Box maxW="md" w="100%" p={4}>
          <Text size="3xl">Open Bets</Text>
          {calculateOpenBets().toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          })}
        </Box>
      </HStack>

      {/* Month Drop Down */}
      <HStack w="80%">
        <Select
          w="100%"
          placeholder="All Items"
          p="0.5em"
          onChange={handleFilterMonth}
          value={filterMonth}
        >
          {calcMonthOptions().map((month: string) => (
            <option value={month}>{month}</option>
          ))}
        </Select>
        <Select
          w="100%"
          placeholder="All Types"
          p="0.5em"
          onChange={handleFilterType}
          value={filterType}
        >
          <option value="open">Bet (Open)</option>
          <option value="closed">Bet (Closed)</option>
          <option value="income">Income</option>
          <option value="expense">Expense</option>
          <option value="withdraw">Withdraw</option>
        </Select>
        <Button onClick={handleClearFilters}>Clear</Button>
      </HStack>

      {/* Data Accordian */}
      <Accordion p="0.5em" w="80%">
        {filteredData.map((entry: EntryType) => {
          switch (entry.type) {
            case "income":
              return <Income entry={entry} />;
            case "bet":
              return <Bet entry={entry} />;
            case "withdraw":
              return <Income entry={entry} />;
            case "expense":
              return <Expense entry={entry} />;
            default:
              return "Invalid Type";
          }
        })}
      </Accordion>
    </VStack>
  );
};
