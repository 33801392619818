import React, { ChangeEvent } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  VStack,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { StateType } from "../../store/stateType";
import * as AllActionCreators from "../../actions/actionCreators";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useQuery } from "react-query";
import { serverUrl } from "../../config";
import { get_odds, refresh_server_status } from "../../resources/arbitrageAPI";
import { BookiesList } from "./BookiesList";
import { LogType } from "../../types/types";

export const Filters = () => {
  const dispatch = useDispatch();

  const { data } = useQuery("logData", () =>
    fetch(`${serverUrl}/logs`).then((res) => res.json()),
  );

  const {
    changeStake,
    clearScreen,
    setIsLoading,
    setArbitrages,
    updateLogs,
    openArbModal,
  } = bindActionCreators(AllActionCreators, dispatch);

  const { stake, filepath } = useSelector((state: StateType) => state);

  const handleChangeStake = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    changeStake(parseFloat(e.target.value));
    return setIsLoading(false);
  };

  const handleRefreshState = async () => {
    setIsLoading(true);
    const data = await refresh_server_status();
    if (data.status === 205) {
      updateLogs("Log data refreshed", "info");
    }
    return setIsLoading(false);
  };

  const handleQuickArbClick = () => {
    openArbModal(true);
  };

  const handleChangeLog = async (e: ChangeEvent<HTMLSelectElement>) => {
    setIsLoading(true);

    const response = await get_odds(e.target.value, stake);
    const log_data = response.data;

    setArbitrages(log_data);

    return setIsLoading(false);
  };

  return (
    <VStack
      backgroundColor="#EFEEEF"
      h="100vh"
      overflowY="auto"
      w="25%"
      sx={{
        paddingTop: "0.25em",
        paddingLeft: "0.25em",
        paddingRight: "0.25em",
      }}
    >
      {/* Stake */}
      <InputGroup size="sm">
        <InputLeftAddon children="£ " />
        <Input
          bg="#FFFFFF"
          type="number"
          placeholder="Stake"
          variant="flushed"
          value={stake}
          onChange={handleChangeStake}
        />
      </InputGroup>
      {/* Logs Dropdown */}
      <Select
        bg="#FFFFFF"
        placeholder="Select Date"
        variant="outline"
        value={filepath}
        onChange={handleChangeLog}
      >
        {data?.map((log: LogType) => (
          <option
            key={log.filepath}
            value={log.filepath}
            disabled={log?.arbs_total === 0}
          >
            {log.filepath} ({log.arbs_total})
          </option>
        ))}
      </Select>
      <BookiesList />
      <VStack>
        <Button variant="outline" onClick={handleQuickArbClick} bg="#FFFFFF">
          Quick Arb
        </Button>
        <Button variant="outline" onClick={handleRefreshState} bg="#FFFFFF">
          Refresh
        </Button>
        <Button variant="outline" onClick={clearScreen} bg="#FFFFFF">
          Clear
        </Button>
      </VStack>
    </VStack>
  );
};
