import axios from "axios";

import { serverUrl } from "../config";

export const get_odds = async (filepath, stake, bookies = []) => {
  let url = `${serverUrl}/odds?stake=${stake}&filepath=${filepath}`;
  if (bookies.length > 0) {
    const bookiesString = bookies.map((b) => encodeURIComponent(b)).join(",");
    url += `&bookies=${bookiesString}`;
  }
  return await axios.get(url, {
    params: {
      stake,
    },
  });
};

export const update_stake = async (stake, bookies) => {
  const names = bookies.filter((b) => b.active).map((b) => b.name);

  const url = `${serverUrl}/update_stake?stake=${stake}`;
  return await axios.get(url, {
    params: {
      stake,
      bookies: JSON.stringify(names),
    },
  });
};

export const get_past_events = async () => {
  const url = `${serverUrl}/logs`;
  return await axios.get(url);
};

export const refresh_server_status = async () => {
  const url = `${serverUrl}/refresh`;
  return await axios.patch(url);
};
