import React from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  HStack,
} from "@chakra-ui/react";
import { Card } from "../../Homepage/Card";
import { EntryType } from "../../../types/types";

type BetProps = {
  entry: EntryType;
};

export const Bet = ({ entry }: BetProps) => {
  const { date, amount, open, bet } = entry;

  const getBackgroundColour = () => {
    let total = 0;
    if (open) {
      total = amount;
    } else {
      if (bet) {
        total = bet.winnings;
      }
    }
    return total > 0 ? "green.200" : "red.200";
  };

  const handleOpenProof = () => {
    if (bet) {
      window.open(bet.proof, "_blank");
    }
  };

  const convertToGBP = (amount: number) => {
    return amount.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });
  };

  if (bet) {
    return (
      <AccordionItem w="100%">
        <h2>
          <AccordionButton bg={getBackgroundColour()} _hover={undefined}>
            <Box flex="1" textAlign="left">
              <HStack justify="space-between">
                <div>{date}</div>
                <div>
                  {bet.home} vs {bet.away}
                </div>
                {open && <Badge>Open Bet</Badge>}
                <div>
                  {!open
                    ? convertToGBP(bet.winnings)
                    : amount !== undefined && convertToGBP(amount)}
                </div>
              </HStack>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>
          {bet.proof && (
            <HStack paddingBottom="0.5em" justify="center">
              <Button onClick={handleOpenProof}>Open Proof</Button>
            </HStack>
          )}
          <Card arbitrage={bet} />
        </AccordionPanel>
      </AccordionItem>
    );
  }
  return <></>;
};
