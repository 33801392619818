import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "../reducers/rootReducer";
import thunk from "redux-thunk";

import { initialState } from "./initialstate";

export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk),
);
