import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
  VStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from "@chakra-ui/react";
import * as AllActionCreators from "../../actions/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { StateType } from "../../store/stateType";

export const ArbModal = () => {
  const dispatch = useDispatch();

  const { openArbModal } = bindActionCreators(AllActionCreators, dispatch);
  const { isArbModalOpen } = useSelector((state: StateType) => state);
  const [stake, setStake] = useState(100);
  const [homeOdds, setHomeOdds] = useState<number | undefined>(undefined);
  const [homeStake, setHomeStake] = useState("");
  const [drawOdds, setDrawOdds] = useState<number | undefined>(undefined);
  const [drawStake, setDrawStake] = useState("");
  const [awayOdds, setAwayOdds] = useState<number | undefined>(undefined);
  const [awayStake, setAwayStake] = useState("");
  const [winnings, setWinnings] = useState<number | undefined>(undefined);

  useEffect(() => {
    recalculateArbs();
  }, [stake, homeOdds, drawOdds, awayOdds]);

  const handleUpdateDetails = (key: string, value: string) => {
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue) || value === "") {
      switch (key) {
        case "stake": {
          setStake(numberValue);
          break;
        }
        case "homeOdds": {
          setHomeOdds(numberValue);
          break;
        }
        case "drawOdds": {
          setDrawOdds(numberValue);
          break;
        }
        case "awayOdds": {
          setAwayOdds(numberValue);
          break;
        }
        default:
          return "";
      }
    }
  };

  const recalculateArbs = () => {
    if (stake && homeOdds && drawOdds && awayOdds) {
      const localHomeStake =
        stake / (1 + homeOdds / awayOdds + homeOdds / drawOdds);
      const localDrawStake =
        stake / (1 + drawOdds / homeOdds + drawOdds / awayOdds);
      const localAwayStake =
        stake / (1 + awayOdds / drawOdds + awayOdds / homeOdds);

      setHomeStake(localHomeStake.toFixed(2));
      setDrawStake(localDrawStake.toFixed(2));
      setAwayStake(localAwayStake.toFixed(2));

      return setWinnings(localHomeStake * homeOdds - stake);
    }
  };

  const handleCloseModal = () => {
    openArbModal(false);
  };

  return (
    <>
      <Modal isOpen={isArbModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Quick Arb</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <InputGroup size="sm">
                <InputLeftAddon children="£ " />
                <Input
                  bg="#FFFFFF"
                  type="number"
                  variant="flushed"
                  placeholder="Stake"
                  value={stake}
                  textAlign="center"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleUpdateDetails("stake", e.target.value)
                  }
                />
              </InputGroup>
              <HStack>
                <VStack paddingBottom="1em">
                  <div>H</div>
                  <Input
                    bg="#FFFFFF"
                    type="number"
                    placeholder="H Odds"
                    value={homeOdds}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleUpdateDetails("homeOdds", e.target.value)
                    }
                    textAlign="center"
                  />
                  <Text>£{homeStake}</Text>
                </VStack>
                <VStack paddingBottom="1em">
                  <div>D</div>
                  <Input
                    bg="#FFFFFF"
                    type="number"
                    placeholder="D Odds"
                    value={drawOdds}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleUpdateDetails("drawOdds", e.target.value)
                    }
                    textAlign="center"
                  />
                  <Text>£{drawStake}</Text>
                </VStack>
                <VStack paddingBottom="1em">
                  <div>A</div>
                  <Input
                    bg="#FFFFFF"
                    type="number"
                    placeholder="A Odds"
                    value={awayOdds}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleUpdateDetails("awayOdds", e.target.value)
                    }
                    textAlign="center"
                  />
                  <Text>£{awayStake}</Text>
                </VStack>
              </HStack>
              {winnings && (
                <HStack
                  paddingBottom="1em"
                  color={winnings > 0 ? "green" : "red"}
                  fontWeight={winnings > 0 ? "bold" : undefined}
                >
                  <Text fontSize="xs">Winnings</Text>
                  <Text fontSize="xs">:</Text>
                  <Text fontSize="lg">£{winnings.toFixed(2)}</Text>
                </HStack>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
