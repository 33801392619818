import React from "react";
import { EntryType } from "../../../types/types";
import { GenericItem } from "./GenericItem";

type ExpenseProps = {
  entry: EntryType;
};

export const Expense = ({ entry }: ExpenseProps) => {
  return (
    <GenericItem
      type={entry.type}
      date={entry.date}
      amount={entry.amount}
      description={entry.description}
    />
  );
};
