import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { HStack, useMediaQuery } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CardList } from "./CardList";
import { Filters } from "./Filters";
import { Logger } from "./Logger";
import { StateType } from "../../store/stateType";
import { get_odds } from "../../resources/arbitrageAPI";
import * as AllActionCreators from "../../actions/actionCreators";
import { bindActionCreators } from "redux";
import { ArbModal } from "./ArbModal";

export const Homepage = () => {
  const dispatch = useDispatch();
  const [isMobile] = useMediaQuery("(min-width: 800px)");
  const [searchParams] = useSearchParams();
  const logsParam = searchParams.get("logs");
  const { isLoading, logs } = useSelector((state: StateType) => state);

  const { setArbitrages } = bindActionCreators(AllActionCreators, dispatch);

  useEffect(() => {
    const fetch = async () => {
      try {
        if (logsParam !== null) {
          const { data } = await get_odds(logsParam, 100);
          setArbitrages(data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetch();
  }, [logsParam]);

  return (
    <LoadingOverlay active={isLoading} spinner>
      <HStack w="full" spacing={0} style={{ height: "100vh" }}>
        <Filters />
        <CardList />
        {isMobile ? <Logger logs={logs} /> : undefined}
      </HStack>
      <ArbModal />
    </LoadingOverlay>
  );
};
