import React from "react";
import { Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Homepage } from "./components/Homepage/Homepage";
import { ProgressPage } from "./components/ProgressPage/ProgressPage";

export const App = () => (
  <Box textAlign="center" fontSize="xl">
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/progress" element={<ProgressPage />} />
      </Routes>
    </Router>
  </Box>
);
