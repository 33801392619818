import moment from "moment";
import { Action, ArbitrageType } from "../actions/actionTypes";
import { ActionType } from "../actions/actionTypes";
import { initialState } from "../store/initialstate";

export const rootReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SERVER_CONNECT: {
      return {
        ...state,
        logs: [
          {
            date: action.date,
            message: `Server Connected`,
            severity: action.severity,
          },
        ],
      };
    }
    case ActionType.IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case ActionType.UPDATE_FILEPATH: {
      return {
        ...state,
        filepath: action.filepath,
      };
    }
    case ActionType.GET_ARBITRAGES: {
      const start = new Date();

      return {
        ...state,
        bookies: action.data.bookies,
        arbitrages: action.data.arbitrages,
        filepath: action.data.filepath,
        logs: [
          {
            date: moment(start).format("YYYY-MM-DD HH:mm:ss"),
            message: `${action.data.filepath} data loaded`,
            severity: "info",
          },
          ...state.logs,
        ],
      };
    }
    case ActionType.TOGGLE_BOOKIE: {
      return {
        ...state,
      };
    }
    case ActionType.UPDATE_LOGS: {
      return {
        ...state,
        logs: [
          {
            date: action.date,
            message: action.message,
            severity: action.severity,
          },
          ...state.logs,
        ],
      };
    }
    case ActionType.CLEAR_SCREEN: {
      return {
        ...state,
        logs: [],
        bookies: [],
        arbitrages: [],
      };
    }
    case ActionType.UPDATE_STAKE: {
      const arbitrages = JSON.parse(JSON.stringify(state.arbitrages));

      arbitrages.map((ab: ArbitrageType) => {
        ab.odds.h.stake =
          action.stake /
          (1 +
            ab.odds.h.odds / ab.odds.a.odds +
            ab.odds.h.odds / ab.odds.d.odds);
        ab.odds.d.stake =
          action.stake /
          (1 +
            ab.odds.d.odds / ab.odds.h.odds +
            ab.odds.d.odds / ab.odds.a.odds);
        ab.odds.a.stake =
          action.stake /
          (1 +
            ab.odds.a.odds / ab.odds.d.odds +
            ab.odds.a.odds / ab.odds.h.odds);
        ab.winnings = ab.odds.h.stake * ab.odds.h.odds - action.stake;
        return ab;
      });

      return {
        ...state,
        stake: action.stake,
        arbitrages: arbitrages,
      };
    }
    case ActionType.OPEN_ARB_MODAL: {
      return {
        ...state,
        isArbModalOpen: action.isOpen,
      };
    }
    default:
      return state;
  }
};
