import React from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { Card } from "./Card";
import { useSelector } from "react-redux";

import { ArbitrageType } from "../../types/types";
import { StateType } from "../../store/stateType";

// useMediaQuery

export const CardList = () => {
  const { arbitrages } = useSelector((state: StateType) => state);

  return (
    <SimpleGrid
      minChildWidth={["200px", "300px"]}
      p="20px"
      spacing="20px"
      w="85%"
      h="100vh"
      overflowY="auto"
    >
      {arbitrages.length === 0
        ? "No Data!"
        : arbitrages.map((arb: ArbitrageType) => <Card arbitrage={arb} />)}
    </SimpleGrid>
  );
};
