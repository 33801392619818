import React from "react";
import { Box, HStack, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { ArbitrageType, MatchObject } from "../../types/types";
import { StateType } from "../../store/stateType";

interface CardProps {
  arbitrage: ArbitrageType;
}

export const Card = (props: CardProps) => {
  const arbitrage = props.arbitrage;
  const [isMobile] = useMediaQuery("(min-width: 800px)");
  const { stake } = useSelector((state: StateType) => state);

  const calcWinningsPerDay = () => {
    if (stake === 0) {
      return 0.0;
    }
    const betDate: number = Date.parse(arbitrage.date.split(" ")[0]);
    const today: number = Date.parse(new Date().toISOString().slice(0, 10));

    let diff = betDate - today;

    diff = Math.floor(diff / 1000 / 60 / 60 / 24);

    if (diff === 0) {
      return arbitrage.winnings.toFixed(2);
    } else {
      return (arbitrage.winnings / diff).toFixed(2);
    }
  };

  const calcPercentageProfit = () => {
    const percent_profit = (arbitrage.winnings / calcStake()) * 100;
    return percent_profit.toFixed(2);
  };

  const isDateinPast = (date: string) => {
    const today = new Date();
    return new Date(date.split(" ")[0]) < today;
  };

  const setBorderColour = (): string | undefined => {
    const percentProfit: number = parseFloat(calcPercentageProfit());

    if (percentProfit >= 1) {
      return "green.300";
    } else if (percentProfit >= 0.5) {
      return "yellow.200";
    }
    return undefined;
  };

  const calcBorder = () => {
    const percentProfit: number = parseFloat(calcPercentageProfit());

    if (percentProfit >= 0.5) {
      return "5px solid";
    }
    return undefined;
  };

  const calcStake = (): number => {
    let totalStake = 0;
    Object.keys(arbitrage.odds).forEach(
      (k) => (totalStake += arbitrage.odds[k as keyof MatchObject].stake),
    );

    return totalStake;
  };

  return (
    <Box
      bg="#f5f5f5"
      border={calcBorder()}
      borderColor={setBorderColour()}
      borderRadius="25px"
      p={4}
      maxH={isMobile ? "360" : "480"}
    >
      <VStack>
        <Text fontSize="xs">{arbitrage.date}</Text>
        <Text fontSize="lg">
          {arbitrage.home} vs {arbitrage.away}
        </Text>
        <Text fontSize="sm">{arbitrage.competition}</Text>
        <HStack align="space-between" paddingTop="0.5em" paddingBottom="0.5em">
          <VStack>
            <Text fontSize="xs">H</Text>
            <Text fontSize="sm">{arbitrage.odds.h.bookie}</Text>
            <Text fontSize="sm">{arbitrage.odds.h.odds}</Text>
            <Text fontSize="lg">£{arbitrage.odds.h.stake.toFixed(2)}</Text>
          </VStack>
          <VStack>
            <Text fontSize="xs">D</Text>
            <Text fontSize="sm">{arbitrage.odds.d.bookie}</Text>
            <Text fontSize="sm">{arbitrage.odds.d.odds}</Text>
            <Text fontSize="lg">£{arbitrage.odds.d.stake.toFixed(2)}</Text>
          </VStack>
          <VStack>
            <Text fontSize="xs">A</Text>
            <Text fontSize="sm">{arbitrage.odds.a.bookie}</Text>
            <Text fontSize="sm">{arbitrage.odds.a.odds}</Text>
            <Text fontSize="lg">£{arbitrage.odds.a.stake.toFixed(2)}</Text>
          </VStack>
        </HStack>
        <VStack>
          <HStack>
            <Text fontSize="xs">Winnings</Text>
            <Text fontSize="xs">:</Text>
            <Text fontSize="lg">£{arbitrage.winnings.toFixed(2)}</Text>
          </HStack>
          {!isDateinPast(arbitrage.date) && (
            <HStack>
              <Text fontSize="xs">Winnings per Day</Text>
              <Text fontSize="xs">:</Text>
              <Text fontSize="lg">£{calcWinningsPerDay()}</Text>
            </HStack>
          )}
          <HStack>
            <Text fontSize="xs">% Profit</Text>
            <Text fontSize="xs">:</Text>
            <Text fontSize="lg">{calcPercentageProfit()}%</Text>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};
