import {
  AccordionItem,
  AccordionButton,
  Box,
  HStack,
  AccordionIcon,
  Badge,
} from "@chakra-ui/react";
import React from "react";

type GenericItemProps = {
  type: string;
  date: string;
  amount: number;
  description: string;
  panel?: keyof JSX.IntrinsicElements; // TODO is this correct?
};

export const GenericItem = (props: GenericItemProps) => {
  const { type, date, amount, description, panel } = props;

  return (
    <AccordionItem w="100%">
      <h2>
        <AccordionButton
          bg={amount > 0 ? "green.200" : "red.200"}
          _hover={undefined}
          //_expanded={{ bg: "tomato", color: "white" }}
        >
          <Box flex="1" textAlign="left">
            <HStack justify="space-between">
              <div>{date}</div>
              <div>{description}</div>
              {type === "bet" && <Badge>Open Bet</Badge>}
              <div>
                {amount !== undefined &&
                  amount.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })}
              </div>
            </HStack>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      {panel}
    </AccordionItem>
  );
};
