import { ActionType, ArbitrageType, Bookie } from "./actionTypes";
import { Dispatch } from "redux";
import moment from "moment";

export const clearScreen = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.CLEAR_SCREEN,
    });
  };
};

export const changeStake = (stake: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.UPDATE_STAKE,
      stake,
    });
  };
};

type ArbDataType = {
  arbitrages: ArbitrageType[];
  bookies: Bookie[];
  filepath: string;
  timestamp: string;
  stake: number;
};

export const setArbData = (data: ArbDataType) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.GET_ARBITRAGES,
      arbitrages: data.arbitrages,
      bookies: data.bookies,
      filepath: data.filepath,
      timestamp: data.timestamp,
    });
  };
};

export const setIsLoading = (isLoading: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.IS_LOADING,
      isLoading,
    });
  };
};

export const setArbitrages = (data: ArbDataType) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.GET_ARBITRAGES,
      data,
    });
  };
};

export const updateLogs = (message: string, severity: string) => {
  const start = new Date();

  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.UPDATE_LOGS,
      date: moment(start).format("YYYY-MM-DD HH:mm:ss"),
      message,
      severity,
    });
  };
};

export const openArbModal = (isOpen: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.OPEN_ARB_MODAL,
      isOpen,
    });
  };
};
