export enum ActionType {
  SERVER_CONNECT = "SERVER_CONNECT",
  UPDATE_FILEPATH = "UPDATE_FILEPATH",
  GET_ARBITRAGES = "GET_ARBITRAGES",
  TOGGLE_BOOKIE = "TOGGLE_BOOKIE",
  UPDATE_LOGS = "UPDATE_LOGS",
  CLEAR_SCREEN = "CLEAR_SCREEN",
  UPDATE_STAKE = "UPDATE_STAKE",
  IS_LOADING = "IS_LOADING",
  OPEN_ARB_MODAL = "OPEN_ARB_MODAL",
}

type OpenArbModalAction = {
  type: ActionType.OPEN_ARB_MODAL;
  isOpen: boolean;
};

type IsLoadingAction = {
  type: ActionType.IS_LOADING;
  isLoading: boolean;
};

type OddsAPIType = {
  date?: string;
  arbitrages: ArbitrageType[];
  bookies: Bookie[];
  filepath: string;
  stake: number;
  timestamp: string;
};

interface ServerConnectAction {
  type: ActionType.SERVER_CONNECT;
  date: string;
  severity: string;
}

interface UpdateFilepathAction {
  type: ActionType.UPDATE_FILEPATH;
  filepath: string;
}

interface GetArbitrageAction {
  type: ActionType.GET_ARBITRAGES;
  filepath: string;
  bookies: string[];
  data: OddsAPIType;
}

interface ToggleBookieAction {
  type: ActionType.TOGGLE_BOOKIE;
  bookie: string;
}

interface UpdateLogsAction {
  type: ActionType.UPDATE_LOGS;
  date: string;
  message: string;
  severity: string;
}

interface UpdateStakeAction {
  type: ActionType.UPDATE_STAKE;
  stake: number;
}

interface ClearScreenAction {
  type: ActionType.CLEAR_SCREEN;
}

export type Bookie = {
  name: string;
  active: boolean;
  link?: string;
};

export type ArbitrageType = {
  date: string;
  home: string;
  away: string;
  competition: string;
  odds: OddsType;
  stake: number;
  winnings: number;
};

type OddsType = {
  h: testType;
  d: testType;
  a: testType;
};

type testType = {
  bookie: string;
  stake: number;
  odds: number;
};

export type Action =
  | ServerConnectAction
  | UpdateFilepathAction
  | GetArbitrageAction
  | ToggleBookieAction
  | UpdateLogsAction
  | ClearScreenAction
  | UpdateStakeAction
  | IsLoadingAction
  | OpenArbModalAction;
