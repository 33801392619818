import React from "react";
import { HStack, VStack, Text } from "@chakra-ui/react";
import { MessageType } from "../../types/types";

type LoggerProps = {
  logs: MessageType[];
};

export const Logger = ({ logs }: LoggerProps) => {
  return (
    <VStack
      w="35%"
      h="100vh"
      overflowY="auto"
      align="start"
      p="1rem"
      sx={{
        backgroundColor: "#300A24",
        fontFamily: "Anonymous Pro",
        fontSize: "0.75em",
      }}
    >
      {logs.map((log: MessageType, index: number) => {
        return (
          <HStack key={index} direction="row" justify="space-around">
            <Text style={{ color: "#77B530" }}>{log.date}</Text>
            <Text
              style={{
                color:
                  log.severity === "error"
                    ? "#FF0000"
                    : log.severity === "warning"
                    ? "#FFFF00"
                    : "#FFFFFF",
              }}
            >
              {log.message}
            </Text>
          </HStack>
        );
      })}
    </VStack>
  );
};
